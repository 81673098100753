import React from "react"
import { graphql } from "gatsby"
import Layout from "./../components/layouts/Default"
import Breadcrumb from "../components/common/Breadcrumb"
import Container from "../components/layouts/container/Container"
import ServiceList from "../components/common/ServiceList"
import SectionTitle from "../components/common/SectionTitle"
import Cta from "../components/common/Cta"
import HeaderImage from "../components/common/HeaderImage"

export default function Page({ data, location }) {
  const svg = {
    check: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    ),
  }

  const services = [
    {
      to: "/leistungen/immobilienbewertung/",
      title: "Immobilienbewertung",
      text: "Wir ermitteln ganz einfach den Wert Ihrer Immobilie.",
    },
    {
      to: "/leistungen/expose/",
      title: "Erstellung Exposé",
      text:
        "Die Grundlage für einen erfolgreichen und zeitnahen Verkauf eines Hauses oder einer Wohnung.",
    },
    {
      to: "/leistungen/grundriss/",
      title: "Anfertigung Grundriss",
      text:
        "Einfach schöne und günstige Grundrisse und Visualisierungen Ihrer Immobilie.",
    },
    {
      to: "/leistungen/vermarktung/",
      title: "Vermarktung auf Internetportalen",
      text:
        "Wir vermarkten Ihre Immobilie und verbreiten diese in den gängigen Internetportalen.",
    },
    {
      to: "/leistungen/energieausweis/",
      title: "Erstellung Energieausweis",
      text:
        "Der Energieausweis ist ein Dokument, das Daten zur Energieeffizienz und zu den anfallenden Energiekosten eines Gebäudes liefern soll.",
    },
    {
      to: "/leistungen/wertermittlung/",
      title: "Wertermittlung",
      text: "Wertermittlung Ihrer Immobilie.",
    },
  ]
  return (
    <Layout
      location={location}
      title="Leistungen | Döbbel Immobilien"
      name="Leistungen"
      desc=""
    >
      <HeaderImage img={data.header.childImageSharp.fluid} />
      <Breadcrumb links={[{ link: "Leistungen", to: "/leistungen/" }]} />
      <Container className="my-8 md:my-16">
        <SectionTitle
          title="Leistungsübersicht"
          subtitle="Leistungen"
          text="Sie suchen einen Mieter oder einen Käufer? Wir vermitteln gerne und sorgen dafür, dass Sie mit Ihrer Immobilie das beste Ergebnis erzielen. Bei uns bekommen Sie genau die Beratung und Unterstützung, die Sie sich wünschen."
        />
        <ServiceList services={services} />
      </Container>
      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    header: file(relativePath: { eq: "leistungen-header.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
