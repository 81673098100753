import React from "react"
import { Link } from "gatsby"

const ServiceList = ({ services }) => {
  return (
    <dl className="space-y-8 divide-y divide-gray-200">
      {services.map((service, i) => {
        return (
          <div className="pt-6 md:grid md:grid-cols-12 md:gap-8" key={i}>
            <dt className="text-lg font-medium text-brand md:col-span-5">
              <Link
                to={service.to}
                className="text-brand hover:underline target:underline"
              >
                {service.title}
              </Link>
            </dt>
            <dd className="mt-2 md:mt-0 md:col-span-7">
              <p className="mb-3 text-base text-gray-500">{service.text}</p>
              <Link
                to={service.to}
                className="text-brand hover:underline target:underline"
              >
                Mehr erfahren{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="inline w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </Link>
            </dd>
          </div>
        )
      })}
    </dl>
  )
}

export default ServiceList
